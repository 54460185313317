require('./src/styles/global.css');

// exports.onPreRouteUpdate = ({ location, prevLocation }) => {
//     window.scrollTo(0, 0)
// }

// exports.shouldUpdateScroll = ({
//     routerProps: { location },
//     getSavedScrollPosition,
// }) => {
//     const { pathname, hash } = location
//     const scrollToTopRoutes = [
//         `/contact-us/`,`/pt/contact-us/`,`/es/contact-us/`,`/en/contact-us/`,
//         `/privacy-policy/`,`/pt/privacy-policy/`,`/es/privacy-policy/`,`/en/privacy-policy/`,
//         `/terms-of-use/`,`/pt/terms-of-use/`,`/es/terms-of-use/`,`/en/terms-of-use/`
//     ]
//     if (scrollToTopRoutes.indexOf(pathname) !== -1) {
//         window.scrollTo(0, 0)
//     }
//     const scrollToHash = [
//         `#our-story`, `#our-kombucha`, `#what-is-kombucha`
//     ]
    
//     return (scrollToHash.indexOf(hash) !== -1) ? true : false
// }